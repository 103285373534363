/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  styled,
  Button,
  Card,
} from "@mui/material";
import colors from "assets/theme/base/colors";
import closeImg from "assets/images/close_X.svg";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";

export function UserCardCreate({
  open,
  handleClose,
  isFormModified,
  isDisabled,
  getFieldValue,
  handleFieldChange,
  handleSubmit,
  isFieldValid,
  bloodTypes,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "700px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    maxHeight: "95vh",
  };

  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "15px",
  };

  const photo = getFieldValue("photo");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Crear usuario
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Subir foto
            </Typography>
            {/* Input para seleccionar un archivo de imagen en formato PNG */}
            <input
              type="file"
              accept="image/png" // Solo acepta archivos PNG
              onChange={(e) => {
                const file = e.target.files[0]; // Obtiene el primer archivo seleccionado
                if (file && file.type !== "image/png") {
                  alert("Solo se permiten imágenes en formato PNG."); // Muestra una alerta
                  e.target.value = null; // Resetea el input file
                  return;
                }
                handleFieldChange("photo", file); // Llama a la función handleFieldChange con el archivo seleccionado
              }}
              style={{ marginTop: "5px" }}
              disabled={isDisabled}
            />
            {/* Mostrar la imagen seleccionada */}
            {photo ? (
              <img src={photo} alt="Imagen seleccionada" style={{ maxWidth: "20%" }} />
            ) : null}
          </Box>
          <div style={{ maxHeight: "80%", overflowY: "auto" }}>
            <Box sx={content}>
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Nombre
                </Typography>
                <MDInput
                  disabled={isDisabled}
                  style={{ marginTop: "5px" }}
                  label=""
                  error={!isFieldValid.name}
                  helperText={!isFieldValid.name ? "Ingrese un nombre valido." : ""}
                  FormHelperTextProps={{ className: !isFieldValid.name ? "Mui-error" : "" }}
                  value={getFieldValue("name")}
                  onChange={(e) => handleFieldChange("name", e.target.value)}
                />
              </Box>
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Apellido
                </Typography>
                <MDInput
                  disabled={isDisabled}
                  style={{ marginTop: "5px" }}
                  error={!isFieldValid.lastname}
                  helperText={!isFieldValid.lastname ? "Ingrese un apellido valido." : ""}
                  FormHelperTextProps={{ className: !isFieldValid.lastname ? "Mui-error" : "" }}
                  label=""
                  value={getFieldValue("lastname")}
                  onChange={(e) => handleFieldChange("lastname", e.target.value)}
                />
              </Box>
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Documento de identidad
                </Typography>
                <MDInput
                  disabled={isDisabled}
                  style={{ marginTop: "5px" }}
                  error={!isFieldValid.number_id}
                  helperText={
                    !isFieldValid.number_id ? "Ingrese un número de identificación válido." : ""
                  }
                  FormHelperTextProps={{ className: !isFieldValid.number_id ? "Mui-error" : "" }}
                  label=""
                  value={getFieldValue("number_id")}
                  onChange={(e) => handleFieldChange("number_id", e.target.value)}
                />
              </Box>
              <Box>
                <Typography id="modal-modal-description333" sx={{ mt: 2 }}>
                  Tipo de sangre
                </Typography>
                <FormControl sx={{ minWidth: 80 }} error={!isFieldValid.blood_type}>
                  <Select
                    labelId="labelId-select-tipo-de-fuente"
                    id="select-tipo-de-fuente"
                    value={getFieldValue("blood_type") ? getFieldValue("blood_type") : ""}
                    onChange={(e) => handleFieldChange("blood_type", e.target.value)}
                    style={{ fontSize: "15px", padding: "10px" }}
                    disabled={isDisabled}
                    displayEmpty
                  >
                    <MenuItem value={""}>Seleccione...</MenuItem>
                    {bloodTypes
                      ? bloodTypes.map((type) => {
                          return (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })
                      : []}
                  </Select>
                  {!isFieldValid.name && <FormHelperText>Tipo de sangre requerida</FormHelperText>}
                </FormControl>
              </Box>
            </Box>
          </div>
          <Divider variant="middle" />
          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            m={-0.5}
            mb={-2}
          >
            <MDButton color="error" onClick={handleClose}>
              Cancelar
            </MDButton>
            <MDButton
              onClick={handleSubmit}
              type="submit"
              disabled={isDisabled || isFormModified}
              color="success"
            >
              Guardar
            </MDButton>
          </MDBox>
        </form>
      </Card>
    </Modal>
  );
}

UserCardCreate.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isDisabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFormModified: PropTypes.bool,
};
