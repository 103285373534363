/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import React, { useEffect, useState, useRef } from "react";
import UserCard from "../modules/UserCard";
import {
  editUserCard,
  getCardUserFilter,
  getCardUsers,
  deleteUserCard,
  createCardUser,
} from "../services/userCardService";
import { CardController } from "layouts/card/controllers/CardController";
import { ModalEditUser } from "../modules/ModalEditUser";
import { UserCardDelete } from "../modules/UserCardDelete";
import { UserCardCreate } from "../modules/UserCardCreate";
import FilterController from "components/filter/controller/FilterController";
import Spinner from "components/MDSpinner";
export function UserCardController() {
  const [dataCardUser, setDataCardUser] = useState();
  const [name, setName] = useState("");
  const [error, setError] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [openBoard, setOpenBoard] = useState(false);
  const [idCardUser, setIdCardUser] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [recoveredData, setRecoveredData] = useState({});
  const [loading, setLoading] = useState(true);
  const filterControllerRef = useRef();

  //Constantes de filtro
  const dataFilter = useState([
    {
      field: "DOCUMENTO",
      value: "number_id",
    },
    {
      field: "NOMBRE O APELLIDO",
      value: "name",
    },
    {
      field: "CORREO ELECTRONICO",
      value: "email",
    },
    {
      field: "FECHA DE CREACION",
      value: "created_at",
    },
    {
      field: "FECHA DE ACTUALIZACION",
      value: "updated_at",
    },
  ]);
  const dataTable = "card_users"; //Tabla indicada

  ////Esperar respuesta de la función getCardUsers y validar respuesta para actualizar la tabla de listado de carnets o mostrar error
  const requestCardUsers = async (pageIndex) => {
    try {
      const result = await getCardUsers(pageIndex);

      //const result = false;
      //await setDataCardUser(data);
      if (result.status !== false) {
        const { data, last_page, total, from, to } = result;
        await setDataCardUser(data);
        await setTotalPages(last_page);
        await setTotalData(total);
        await setEntriesStart(from);
        await setEntriesEnd(to);
      } else {
        setError("Error al consultar al servidor");
      }
    } catch (error) {
      setError("Error al consultar al servidor");
    }
  };

  //Esperar respuesta de la función editUserCard y validar respuesta para actualizar la tabla de listado de carnets y cierra el modal de editar el usuario carnetizado o mostrar error
  const requestEditUserCard = async (id, editTypeCard) => {
    const result = await editUserCard(id, editTypeCard);

    if (result.success !== false) {
      console.log("Actualizo la lista");
      //requestCardUsers(currentPage);
      callHandleClickSubmit();
      handleCloseModalEdit();
    } else {
      setError("Error al consultar al servidor");
    }
  };

  //Setea el número de la pagina elegido a la variable currentPage
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  //Actualiza el valor de las filas por pagina y actualiza la tabla de usuarios carnetizados con las nuevas filas a renderizar
  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    requestCardUsers(currentPage, pageSize);
  };

  //Abre el modal donde se listan los carnets de un usuario en especifico
  const handleOpenCardBoard = (event) => {
    event.preventDefault();
    setOpenBoard(true);
    const { dataset } = event.currentTarget;
    setIdCardUser(dataset.identifier);
    setName(dataset.name);
  };

  //Cierra el modal para listar los carnets de un usuario
  const handleCloseCardController = () => {
    setOpenBoard(false);
  };

  //*********Se implementó en el filterController******
  // useEffect(() => {
  //   requestCardUsers(currentPage);
  // }, [currentPage]);
  //Modal editar ----->

  //Abrir el modal para editar usuario y extraer valores actuales de ese usuario
  const handleOpenModalEdit = (event) => {
    event.preventDefault();
    setOpenModalEdit(true);
    resetFieldValid();
    setIsDisabled(event.currentTarget.dataset.id === "view" ? true : false);
    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.identifier,
      name: dataset.name,
      lastname: dataset.lastname,
      number_id: dataset.numberid,
      blood_type: dataset.bloodtype,
      email: dataset.email,
      photo: dataset.photo,
    };
    setEditedData(data);
  };

  //Cierra el modal de editar un usuario
  const handleCloseModalEdit = () => setOpenModalEdit(false);
  const header = [{}];

  //Obtener valor por clave especifica del objeto
  const getFieldValue = (fieldName) => {
    return editedData[fieldName];
  };

  //Cambiar valor por clave y valor especifico del objeto
  const handleFieldChange = (fieldName, value) => {
    if (fieldName == "photo") {
      const reader = new FileReader();
      let photo;
      reader.onload = () => {
        photo = reader.result;
        setEditedData({
          ...editedData,
          [fieldName]: photo,
        });
      };

      // Leer el archivo como Data URL (Base64)
      reader.readAsDataURL(value);
    } else {
      setEditedData({
        ...editedData,
        [fieldName]: value,
      });
      handleChangeValid(fieldName, value);
    }
  };

  // Comprueba si algún campo ha cambiado desde los valores iniciales
  useEffect(() => {
    const hasFormChanged = Object.keys(editedData).some(
      (fieldName) => editedData[fieldName] !== recoveredData[fieldName]
    );

    const isAnyFieldEmpty = Object.values(editedData).some((value) => value === "");

    setIsFormModified(!isAnyFieldEmpty && hasFormChanged);
  }, [editedData]);

  //Convierte los valores de un objeto  a string
  function convertValuesToString(objeto) {
    const convertedObject = {};

    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        convertedObject[clave] = String(objeto[clave]);
      }
    }

    return convertedObject;
  }

  //ejecuta la funcion requestEditUserCard con la información necesaria despues de ser validada y cierra el modal de editar usuario
  const handleSubmitEdit = (e) => {
    e.preventDefault();
    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        return;
      }
    }
    const convertedObject = convertValuesToString(editedData);
    // Realiza la acción de enviar el formulario aquí
    requestEditUserCard(editedData.id, editedData);
    //requestSourceEdit(editedData.id, editedData);
  };
  //<----------

  // filtrado de usuarios --->
  const [paginaSize, setPaginaSize] = useState(10);

  //  Eliminar usuario------>
  const [openDelete, setOpenDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState({});

  //función que envía la id del usuario a eliminar al la funcion deleteUserCard que esta en la carperta services
  const requestDeleteCardUser = async (id) => {
    const result = await deleteUserCard(id);

    if (result.status !== false) {
      console.log("Actualizo la lista");

      //requestCardUsers(currentPage);
      callHandleClickSubmit();
    } else {
      setError("Error al consultar al servidor");
    }
  };

  // función que abre el modal para eliminar un usuario
  const handleClickDelete = (event) => {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.identifier,
      name: dataset.name,
    };

    setInfoDelete(data);

    setOpenDelete(true);
  };

  //función para cerrar el modal de eliminar usuario
  const handleCloseDelete = () => setOpenDelete(false);

  //función para enviar la id del usuario a eliminar a la función requestDeleteCardUser y cerrar el modal de eliminar usuario
  const handleSubmitDelete = (e) => {
    e.preventDefault();

    requestDeleteCardUser(infoDelete.id);

    handleCloseDelete();
  };
  // <-----------

  // Modal de crear usuario ---->
  const [openNew, setOpenNew] = useState(false);
  const [newData, setNewData] = useState({});
  const [defaultData, setDefaultData] = useState({});

  //función que envía el objeto de datos para crear un usuario a la funcion createCardUser que esta en la carperta services
  const requestCreateCardUser = async (newCardUser) => {
    const result = await createCardUser(newCardUser);

    if (result.status !== false) {
      console.log("Actualizo la lista");
      // requestCardUsers(currentPage);
      callHandleClickSubmit();
    } else {
      setError("Error al consultar al servidor");
    }
  };

  //Abre el modal para crear un usuario
  const handleOpenNew = (event) => {
    event.preventDefault();
    setOpenNew(true);
    setIsDisabled(false);
    //const { dataset } = event.currentTarget;
    const data = {
      name: "",
      lastname: "",
      number_id: "",
      blood_type: "",
      email: "",
      photo: "",
    };
    setDefaultData(data);
    setNewData(data);
    resetFieldValid();
  };

  //Cierra el modal para crear un usuario
  const handleCloseNew = () => setOpenNew(false);

  //Obtener valor por clave especifica del objeto
  const getFieldValueNew = (fieldName) => {
    return newData[fieldName];
  };

  // Función para manejar el cambio de campo
  const handleFieldChangeNew = (fieldName, value) => {
    if (fieldName == "photo") {
      const reader = new FileReader();
      let photo;
      reader.onload = () => {
        photo = reader.result;
        setNewData({
          ...newData,
          [fieldName]: photo,
        });
      };

      // Leer el archivo como Data URL (Base64)
      reader.readAsDataURL(value);
    } else {
      setNewData({
        ...newData,
        [fieldName]: value,
      });
      handleChangeValid(fieldName, value);
    }
  };

  //ejecuta la funcion requestCreateCardUser con la información necesaria despues de ser validada y cierra el modal de crear un usuario
  const handleSubmitNew = (e) => {
    e.preventDefault();
    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        return;
      }
    }
    requestCreateCardUser(newData);
    handleCloseNew();
  };

  // Comprueba si algún campo ha cambiado desde los valores iniciales (vacio o nulo)
  useEffect(() => {
    //const hasFormChanged = Object.keys(newData).some((fieldName) => newData[fieldName] !== "");
    const requiredFields = ["name", "lastname", "number_id", "email"];
    const someFieldIsEmpty = requiredFields.some((fieldName) => !newData[fieldName]);
    setIsFormModified(someFieldIsEmpty);
  }, [newData]);

  // Validaciones --->

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    email: true,
    number_id: true,
    name: true,
    lastname: true,
    blood_type: true,
  });

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      email: true,
      number_id: true,
      name: true,
      lastname: true,
      blood_type: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    name: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]*$/, // Permite letras, tildes, espacios y caracteres especiales
    lastname: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]*$/, // Permite letras, tildes, espacios y caracteres especiales
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Verifica el formato de correo electrónico
    number_id: /^[a-zA-Z0-9]*$/, // Verifica que sea alfanumérico
    blood_type: /^(A|B|AB|O)[+-]$/, // Verifica que sea un tipo de sangre válido
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    const isFieldValid = validateField(value.trim(), validationPatterns[fieldName]);

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  const bloodTypes = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  return (
    <>
      <Spinner loading={loading}></Spinner>
      <FilterController
        dataTable={dataTable}
        data={dataCardUser}
        dataFilter={dataFilter}
        setListData={setDataCardUser}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        requestData={requestCardUsers}
        setError={setError}
        setTotalData={setTotalData}
        setEntriesEnd={setEntriesEnd}
        setEntriesStart={setEntriesStart}
        setLoading={setLoading}
        ref={filterControllerRef}
      ></FilterController>
      <UserCard
        dataCardUser={dataCardUser}
        error={error}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleOpenCardBoard={handleOpenCardBoard}
        handleOpenModalEdit={handleOpenModalEdit}
        handleClickDelete={handleClickDelete}
        handleOpenNew={handleOpenNew}
      ></UserCard>
      {openBoard && (
        <CardController
          openBoard={openBoard}
          idCardUser={idCardUser}
          name={name}
          onClose={handleCloseCardController}
        ></CardController>
      )}
      <UserCardCreate
        open={openNew}
        handleClose={handleCloseNew}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueNew}
        handleFieldChange={handleFieldChangeNew}
        handleSubmit={handleSubmitNew}
        isFieldValid={isFieldValid}
        bloodTypes={bloodTypes}
      />
      <ModalEditUser
        open={openModalEdit}
        handleClose={handleCloseModalEdit}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValue}
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmitEdit}
        isFieldValid={isFieldValid}
        bloodTypes={bloodTypes}
      />
      <UserCardDelete
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        infoDelete={infoDelete}
        handleSubmitDelete={handleSubmitDelete}
      ></UserCardDelete>
    </>
  );
}
