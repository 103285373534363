/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Rafael De Jesus Molina Haeckermann                         ║
║ rafael.molina@est.iudigital.edu.co                         ║
╚════════════════════════════════════════════════════════════╝
*/
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import colors from "assets/theme/base/colors";
import closeImg from "assets/images/close_X.svg";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { Height } from "@mui/icons-material";

export function ModalEditUser({
  open,
  handleClose,
  datosRecuperados,
  datosEditados,
  isFormModified,
  isDisabled,
  getFieldValue,
  handleFieldChange,
  handleSubmit,
  isFieldValid,
  bloodTypes,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "450px",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    maxHeight: "95vh",
  };
  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    align: "center",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: "15px",
  };

  const photo = getFieldValue("photo");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Editar usuario
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <div style={{ maxHeight: "90%", overflowY: "auto" }}>
          <form onSubmit={handleSubmit}>
            <MDBox
              sx={content}
              style={{
                justifyContent: "space-around",
              }}
            >
              <div>
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Subir foto
                  </Typography>
                  <input
                    type="file"
                    accept="image/png"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.type !== "image/png") {
                        alert("Solo se permiten imágenes en formato PNG.");
                        e.target.value = null; // Resetea el input file
                        return;
                      }
                      handleFieldChange("photo", file);
                    }}
                    style={{ marginTop: "5px" }}
                    disabled={isDisabled}
                  />
                  {/* Mostrar la imagen seleccionada */}
                  {photo ? (
                    <img src={photo} alt="Imagen seleccionada" style={{ maxWidth: "20%" }} />
                  ) : null}
                </Box>
              </div>
              <Box>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Nombre de usuario:
                </Typography>
                <MDInput
                  disabled={isDisabled}
                  style={{ marginTop: "5px" }}
                  label=""
                  value={getFieldValue("name")}
                  error={!isFieldValid.name}
                  helperText={!isFieldValid.name ? "Ingrese un nombre valido." : ""}
                  FormHelperTextProps={{ className: !isFieldValid.name ? "Mui-error" : "" }}
                  onChange={(e) => handleFieldChange("name", e.target.value)}
                />
              </Box>
              <Box>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Apellido:
                </Typography>
                <MDInput
                  disabled={isDisabled}
                  style={{ marginTop: "5px" }}
                  label=""
                  value={getFieldValue("lastname")}
                  error={!isFieldValid.lastname}
                  helperText={!isFieldValid.lastname ? "Ingrese un apellido valido." : ""}
                  FormHelperTextProps={{ className: !isFieldValid.lastname ? "Mui-error" : "" }}
                  onChange={(e) => handleFieldChange("lastname", e.target.value)}
                />
              </Box>

              <Box>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Documento de identidad:
                </Typography>
                <MDInput
                  disabled={isDisabled}
                  style={{ marginTop: "5px" }}
                  label=""
                  value={getFieldValue("number_id")}
                  error={!isFieldValid.number_id}
                  FormHelperTextProps={{ className: !isFieldValid.number_id ? "Mui-error" : "" }}
                  helperText={!isFieldValid.number_id ? "Ingrese un documento válido." : ""}
                  onChange={(e) => handleFieldChange("number_id", e.target.value)}
                />
              </Box>
              <Box>
                <Typography id="modal-modal-description333" sx={{ mt: 2 }}>
                  Tipo de sangre
                </Typography>
                <FormControl sx={{ minWidth: 80 }} error={!isFieldValid.blood_type}>
                  <Select
                    labelId="labelId-select-tipo-de-fuente"
                    id="select-tipo-de-fuente"
                    value={getFieldValue("blood_type") ? getFieldValue("blood_type") : ""} // Si no hay valor, se asigna un string vacío
                    onChange={(e) => handleFieldChange("blood_type", e.target.value)}
                    style={{ fontSize: "15px", padding: "10px" }}
                    disabled={isDisabled}
                    displayEmpty
                  >
                    <MenuItem value={""}>Seleccione...</MenuItem>
                    {bloodTypes
                      ? bloodTypes.map((type) => {
                          return (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })
                      : []}
                  </Select>
                  {!isFieldValid.blood_type && (
                    <FormHelperText>Tipo de sangre requerida</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </MDBox>
            <Divider variant="middle" />
            <MDBox
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
              m={-0.5}
              mb={-2}
            >
              <MDButton color="error" onClick={handleClose}>
                Cancelar
              </MDButton>
              <MDButton type="submit" disabled={isDisabled || !isFormModified} color="success">
                Guardar
              </MDButton>
            </MDBox>
          </form>
        </div>
      </Card>
    </Modal>
  );
}

ModalEditUser.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  datosRecuperados: PropTypes.object,
  isDisabled: PropTypes.bool,
  datosEditados: PropTypes.object,
  getFieldValue: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFormModified: PropTypes.bool,
};
